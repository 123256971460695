/**
 *  Copyright 2023 OwlMeans
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

import { Extension } from "@owlmeans/regov-ssi-core"

export type IdentitySubject = {
  identifier: string
  sourceApp: string
  uuid: string
  createdAt: string
}

export type RegovIdentityExtension = Extension

export const REGOV_IDENTITY_DEFAULT_NAMESPACE = 'regov-ext-basic-identity'

export const REGOV_IDENTITY_DEFAULT_TYPE = 'OwlMeans:Regov:Identity'

export const ERROR_NO_EXENSION = 'ERROR_NO_EXENSION'