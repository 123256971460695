/**
 *  Copyright 2023 OwlMeans
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

import { BasicNavigator, WalletNavigator, WalletNavigatorMenuMethod } from "../../../../common"
import { RegistryType } from "@owlmeans/regov-ssi-core"
import { NavigateFunction } from "react-router-dom-regov"


export type ListNavigator = WalletNavigator & BasicNavigator & {
  item: WalletNavigatorMenuMethod<RegistryType, ListNavigatorItemParams>
}

export type ListNavigatorItemParams = { section: string, id: string }

export const partialListNavigator = (navigate: NavigateFunction): {
  item: WalletNavigatorMenuMethod<RegistryType, ListNavigatorItemParams>
} => ({
  item: async (registry, { section, id }: ListNavigatorItemParams) => {
    navigate(`/credential/list/${registry}/${section}/${id}`)
  }
})