/**
 *  Copyright 2023 OwlMeans
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

import { UIExtensionRegistry } from "../extension"
import { i18n } from 'i18next'

export const i18nRegisterExtensions = (i18n: i18n, extensions: UIExtensionRegistry) => {
  extensions?.uiExtensions.forEach(ext => {
    if (ext.extension.localization) {
      Object.entries(ext.extension.localization.translations).forEach(([lng, resource]) => {
        if (ext.extension.localization?.ns) {
          i18n.addResourceBundle(lng, ext.extension.localization?.ns, resource, true, true)
        }
      })
    }
  })
}